<template>
    <div>
        <div class="relative">
            <!-- date fields + icon -->
            <div class="flex flex-col sm:flex-row w-full">
                <!-- date fields -->
                <div class="w-full max-w-xs flex justify-center items-center">
                    <!-- day (for day-first format) -->
                    <div v-if="!question.monthFirstFormat" class="w-1/3 pr-2 sm:pr-8 mt-4 sm:mt-0">
                        <div class="text-left uppercase text-sm">
                            <label :for="`${qName}_datepicker_day`">{{ strings.day }}</label>
                        </div>
                        <div class="portal-input rounded-md">
                            <input
                                :id="`${qName}_datepicker_day`"
                                v-model.number="userInput.day"
                                :name="`${qName}_datepicker_day`"
                                :data-cy="`${qName}-datepicker-day-uk-format`"
                                class="w-full appearance-none px-3 py-2 rounded-md border border-mid-grey high-c-border-black"
                                type="number"
                                placeholder="DD"
                                min="1"
                                max="31"
                                @input="inputDate('day')"
                            />
                            <div class="focus-border"></div>
                        </div>
                    </div>
                    <!-- month -->
                    <div class="w-1/3 pr-2 sm:pr-8 mt-4 sm:mt-0">
                        <div class="text-left uppercase text-sm">
                            <label :for="`${qName}_datepicker_month`">{{ strings.month }}</label>
                        </div>
                        <div class="portal-input rounded-md">
                            <input
                                :id="`${qName}_datepicker_month`"
                                v-model.number="userInput.month"
                                :name="`${qName}_datepicker_month`"
                                :data-cy="`${qName}-datepicker-month`"
                                class="w-full appearance-none px-3 py-2 rounded-md border border-mid-grey high-c-border-black"
                                type="number"
                                placeholder="MM"
                                min="1"
                                max="12"
                                @input="inputDate('month')"
                            />
                            <div class="focus-border"></div>
                        </div>
                    </div>
                    <!-- day (for month-first format) -->
                    <div v-if="question.monthFirstFormat" class="w-1/3 pr-2 sm:pr-8 mt-4 sm:mt-0">
                        <div class="text-left uppercase text-sm">
                            <label :for="`${qName}_datepicker_day`">{{ strings.day }}</label>
                        </div>
                        <div class="portal-input rounded-md">
                            <input
                                :id="`${qName}_datepicker_day`"
                                v-model.number="userInput.day"
                                :name="`${qName}_datepicker_day`"
                                :data-cy="`${qName}-datepicker-day-us-format`"
                                class="w-full appearance-none px-3 py-2 rounded-md border border-mid-grey high-c-border-black"
                                type="number"
                                placeholder="DD"
                                min="1"
                                max="31"
                                @input="inputDate('day')"
                            />
                            <div class="focus-border"></div>
                        </div>
                    </div>
                    <!-- year -->
                    <div class="w-1/3 mt-4 sm:mt-0 order-2">
                        <div class="text-left uppercase text-sm">
                            <label :for="`${qName}_datepicker_year`">{{ strings.year }}</label>
                        </div>
                        <div class="portal-input rounded-md">
                            <input
                                :id="`${qName}_datepicker_year`"
                                v-model.number="userInput.year"
                                :name="`${qName}_datepicker_year`"
                                :data-cy="`${qName}-datepicker-year`"
                                class="w-full appearance-none px-3 py-2 rounded-md border border-mid-grey high-c-border-black"
                                type="number"
                                placeholder="YYYY"
                                min="1900"
                                max="2200"
                                @input="inputDate('year')"
                            />
                            <div class="focus-border"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- validation messages -->
        <div
            :aria-hidden="!validation_message"
            class="relative mt-1 sm:mt-3 bg-warn text-over-warn flex items-center justify-center rounded-lg"
            :class="{ 'opacity-0': !validation_message }"
            :data-cy="`${qName}-validation-error`"
            style="min-height: 2rem"
        >
            <span class="leading-none text-lighter text-sm px-4">{{ validation_message }}</span>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";
import text from "./datePickerText";

export default {
    name: "DatePicker",
    props: {
        question: {
            type: Object,
            required: true,
        },
        surveyId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            userInput: {
                day: "",
                month: "",
                year: "",
            },
            validation: {
                day: null,
                month: null,
                year: null,
                date_range: null, // optional
            },
            field_touched: {
                day: false,
                month: false,
                year: false,
            },
            strings: text,
        };
    },
    computed: {
        validation_message() {
            // Display specific messages only once that field is touched
            if (this.validation.day === false && this.field_touched.day) return this.strings["day-error"];
            else if (this.validation.month === false && this.field_touched.month) return this.strings["month-error"];
            else if (this.validation.year === false && this.field_touched.year) return this.strings["year-error"];
            else if (this.validDateRangeVsToday === "greater_than_or_equal" && this.validation.date_range === false) {
                return this.strings["must-be-future"];
            } else if (this.validDateRangeVsToday === "less_than_or_equal" && this.validation.date_range === false) {
                return this.strings["must-be-past"];
            }

            return "";
        },
        qName() {
            return this.question.name;
        },
        isRequired() {
            return this.question.isRequired;
        },
        existingInput() {
            return this.question.userAnswer;
        },
        validDateRangeVsToday() {
            // does the date selected have to be "less_than_or_equal" or "greater_than_or_equal"
            return this.question.valid_date_range_vs_today;
        },
    },
    created() {
        // console.log("this.existing_input is > ", this.existing_input);
        if (this.existingInput) {
            this.setUserInputtedDates(this.existingInput);
        }
    },
    methods: {
        // restore user input dates by splitting dd/mm/yyyy then set inputted values
        setUserInputtedDates(val) {
            if (!val) return;

            // We expect the date to be returned from the database as a datetime object, with time set to 00:00:00 UTC
            // It will look like this: 1990-01-12T00:00:00+00:00. The easiest way to get the information we want from this
            // without involving any local timezone (which is taken into account when using new Date or some formatting libraries) 
            // & potentially having the timezone difference result in date changes, is to use the piece of the string coming before 'T' [YYYY-MM-DD] and split by '-'  
            const yyyy_mm_dd = val.split("T")[0];
            const y_m_d_array = yyyy_mm_dd.split("-");
            this.userInput = {
                day: y_m_d_array[2] ? Number(y_m_d_array[2]) : "",
                month: y_m_d_array[1] ? Number(y_m_d_array[1]) : "",
                year: y_m_d_array[0] ? Number(y_m_d_array[0]) : "",
            };

            // assume that anything that has previously been successfully saved, is valid
            this.validation = {
                day: true,
                month: true,
                year: true,
                date_range: this.validDateRangeVsToday ? true : null,
            };
            // If we have existing input we should set all fields to 'touched'
            this.field_touched.day = true;
            this.field_touched.month = true;
            this.field_touched.year = true;
        },

        inputDate(field_name) {
            this.processInputAfterDebounce(this, field_name);
        },

        processInputAfterDebounce: debounce((vm, field_name) => {
            // Set this field to 'touched'
            vm.field_touched[field_name] = true;
            // validate the date input if any are not empty (or the date is required!)
            if (vm.isRequired || vm.userInput.day || vm.userInput.month || vm.userInput.year) {
                const is_input_valid = vm.isDateInputValid();
                // if there are no input errors, if 'validate date range' configuration exists > validate the date range
                if (is_input_valid) vm.validateDateRange();
            } else {
                // Reset - no validation needed
                vm.validation = { day: null, month: null, year: null, date_range: null };
            }

            // We'll either emit an error or a date value
            vm.emitInfo();
        }, 50),

        // Optional setting - Is the date selected by the user >=today or <=today (depending on the screen setting)
        validateDateRange() {
            // If validDateRangeVsToday setting is specified, is the selected date <= or >= today
            if (this.validDateRangeVsToday) {
                const today_date = new Date();
                const today = new Date(today_date.getTime());
                today.setHours(0, 0, 0, 0); // set hours to 0 to compare without time
                const user_date = new Date(`${this.userInput.year}-${this.userInput.month}-${this.userInput.day}`);

                // console.log("today.getTime() > ", today);
                // console.log("user_date.getTime() > ", user_date);

                if (this.validDateRangeVsToday === "less_than_or_equal") {
                    // date selected is not less than or equal today
                    if (user_date.getTime() > today.getTime()) {
                        this.validation.date_range = false;
                        return false;
                    }
                } else if (this.validDateRangeVsToday === "greater_than_or_equal") {
                    // date selected is not greater than or equal today
                    if (user_date.getTime() < today.getTime()) {
                        this.validation.date_range = false;
                        return false;
                    }
                }

                this.validation.date_range = true; //reset
                return true;
            }
        },

        // is the inputted date field valid?
        isDateInputValid() {
            // Check inputs extist & fall within specific values depending on the date type
            const inputs = ["day", "month", "year"];
            const mins = [1, 1, 1900];
            const maxes = [31, 12, 2200];

            for (let i = 0; i < inputs.length; i++) {
                const thisField = inputs[i];
                // reset
                this.validation[thisField] = true;
                // has no input, not a valid number or isnt between the min and max options
                if (
                    !this.userInput[thisField] ||
                    !Number.isInteger(this.userInput[thisField]) ||
                    this.userInput[thisField] < mins[i] ||
                    this.userInput[thisField] > maxes[i]
                ) {
                    this.validation[thisField] = false;
                    return false;
                }
            }

            return true;
        },

        // Emit values to surveySlider IF input is valid
        emitInfo() {
            let error_msg = false;

            // Emit errors if the q is required or if any field is filled
            if (this.isRequired || this.userInput.day || this.userInput.month || this.userInput.year) {
                // one of the fields was blank or invalid
                if (!this.validation.day || !this.validation.month || !this.validation.year) {
                    error_msg = "Invalid field";
                }
                // date range was set so check that the selected range was valid
                else if (this.validDateRangeVsToday && !this.validation.date_range) {
                    error_msg = "Date range is invalid";
                }
            }

            // there is a validation error message > dont emit value
            if (error_msg) {
                // console.log("error_msg is > ", error_msg);
                this.$emit("datepicker-error", true);
            }
            // validation successful
            else {
                // console.log("Inside val success day > ", this.userInput.day);
                // all input date fields are valid > build up the full date string
                // add 0 to date input for ex. 1 becomes 01 and if the input was blank (in case of not required) then set it to "-"
                const val_day = this.userInput.day ? (this.userInput.day < 10 ? "0" : "") + this.userInput.day : "";
                const val_month = this.userInput.month ? (this.userInput.month < 10 ? "0" : "") + this.userInput.month : "";
                const val_year = this.userInput.year ? this.userInput.year : "";

                const val = val_day === "" && val_month === "" && val_year === "" ? "" : `${val_year}-${val_month}-${val_day}`;

                this.$emit("datepicker-error", false);
                this.$emit("update", {
                    val,
                    qName: this.qName,
                    surveyId: this.surveyId,
                });
            }
        },
    },
};
</script>
