<template>
    <div class="h-full min-h-screen flex items-center justify-center overflow-hidden">
        <div v-if="loading">
            <p class="sr-only">{{ strings.loading }}</p>
            <LoadingSpinner />
        </div>
        <!-- Needs to be replaced with the welcome survey id for each portal -->
        <SurveySlider v-else :fetchedSurvey="fetchedSurvey" :fetchedSurveyResponse="fetchedSurveyResponse" includeAccountQs completionUrl="/home" />
    </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import LoadingSpinner from "@/components/ui/loadingSpinner";
import SurveySlider from "@/components/survey/surveySlider";
import { User, Survey, Response } from "@/data/models";
import text from "./welcomeText";

export default {
    name: "Welcome",
    components: {
        SurveySlider,
        LoadingSpinner,
    },
    data() {
        return {
            loading: true,
            strings: text,
        };
    },
    async mounted() {
        await Promise.all([
            Survey.$read({ by: [this.welcomeSurveyId] }),
            this.$api.user.read_my_responses_for_surveys([this.welcomeSurveyId]),
            this.$api.user.read_user(),
        ]);

        this.loading = false;
    },
    computed: {
        ...mapFields(["welcomeSurveyId"]),
        user() {
            return User.query().first();
        },
        fetchedSurvey() {
            return Survey.query().where("id", this.welcomeSurveyId).first();
        },
        fetchedSurveyResponse() {
            if (!this.fetchedSurvey) return null;
            return Response.query().where("survey_id", this.welcomeSurveyId).where("user", this.user.id).first();
        },
    },
};
</script>
