<template>
    <div class="flex flex-wrap mt-1 items-stretch justify-center -ml-2" style="width: calc(100% + 1rem)">
        <div
            v-for="(val, index) in valuesArray"
            :key="`${qName}-${val}`"
            class="portal-checkbox my-1 px-1 w-full xs:w-1/2 md:w-1/3 lg:w-1/4"
            style="max-width: 250px"
        >
            <input
                :id="`${qName}-${val}`"
                :data-cy="`${qName}-${val}`"
                :value="val"
                v-model="chosenInputs"
                class="sr-only"
                type="checkbox"
                @change="
                    () => {
                        updateCheckboxes(val);
                    }
                "
            />
            <label class="portal-check-card block relative h-full" :for="`${qName}-${val}`">
                <div class="unselected absolute left-0 right-0 bottom-0 top-0 opacity-50 border border-light-grey bg-lightest-grey rounded"></div>
                <div class="selected absolute left-0 right-0 bottom-0 top-0 bg-primary opacity-0 border-light-grey border rounded"></div>
                <div class="portal-focus absolute left-0 right-0 bottom-0 top-0 opacity-0 rounded"></div>
                <div class="selected-shadow opacity-trans"></div>
                <div class="selected-tick opacity-trans z-20">
                    <div class="portal-tick text-primary"></div>
                </div>
                <div class="relative z-10 p-1 h-full">
                    <div
                        class="mt-3 pt-px px-2 text-lg text-left leading-none text-bolder"
                        style="min-height: 3.25rem"
                        v-html="readableTextOptionsArray[index]"
                    ></div>
                    <div class="text-left py-3 text-portal-sm leading-tight text-lighter pl-2 pr-8">
                        {{ strings.examples[val] }}
                    </div>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
import text from "./medHistCategoriesText";

export default {
    name: "CheckboxCards",
    props: {
        question: {
            type: Object,
            required: true,
        },
        surveyId: {
            type: String,
            required: true,
        },
        answers: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            chosenInputs: [],
            strings: text,
        };
    },
    computed: {
        qName() {
            return this.question.name;
        },
        existingInput() {
            return this.question.userAnswer;
        },
        valuesArray() {
            if (!this.question.choices[0].text) return this.question.choices;
            return Object.values(this.question.choices).map((c) => c.value);
        },
        readableTextOptionsArray() {
            if (!this.question.choices[0].text) return this.question.choices;
            return Object.values(this.question.choices).map((c) => c.text);
        },
    },
    created() {
        if (this.existingInput) this.chosenInputs = this.existingInput;
    },
    watch: {
        // We watch here as the medical history syncing might update this value at any time
        existingInput(val) {
            if (val.length > 0 && val.length !== this.chosenInputs.length) {
                console.log("update from parent");
                this.chosenInputs = val;
            }
        },
    },
    methods: {
        updateCheckboxes(val) {
            // Check to see if this is an empty category which can be unticked or if there is still an inner condition selected
            const inUncheckAttempt = this.answers.med_categories.includes(val);
            const containsCheckedConditions = this.answers[val].length > 0;

            if (inUncheckAttempt && containsCheckedConditions) {
                this.$nextTick(() => {
                    console.log("uncheck");
                    this.$emit("medical-history-sync-error");
                    // The value will have been removed by the uncheck, so we need to re-add it
                    this.chosenInputs.push(val);
                });
            } else {
                this.$emit("update", {
                    val: this.chosenInputs,
                    qName: this.qName,
                    surveyId: this.surveyId,
                });
            }
        },
    },
};
</script>

<style scoped>
.opacity-trans {
    transition: opacity 0.3s ease-in-out;
}

.portal-check-card {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

input {
    position: absolute;
    left: -99999px;
}

.selected-shadow {
    @apply absolute rounded top-0 left-0 w-full h-full opacity-0 shadow-tight;
}

.selected-tick {
    @apply absolute w-8 h-8 rounded opacity-0 right-0 m-2 bg-white flex items-center justify-center;
    bottom: 2px;
}

input:checked + .portal-check-card .selected,
input:checked + .portal-check-card .selected-shadow,
input:checked + .portal-check-card .selected-tick {
    opacity: 0.9;
}

input:checked + .portal-check-card {
    @apply text-white;
    transform: translateY(-2px);
}

input:focus + .portal-check-card .portal-focus {
    opacity: 1;
}
</style>
