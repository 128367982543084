<template>
    <div class="checkbox-holder text-base" :class="{ 'flex flex-wrap': valuesArray.length > 7 }">
        <div
            v-for="(val, index) in valuesArray"
            :key="`${qName}-${val}`"
            :class="{ 'w-full sm:w-1/2 flex-shrink-0 col-margins': valuesArray.length > 7 }"
        >
            <div class="portal-checkbox my-2 bg-lightest-grey rounded-md">
                <input
                    :id="`${qName}-${val}`"
                    :data-cy="`${qName}-${val}`"
                    :value="val"
                    v-model="chosenInputs"
                    class="sr-only"
                    type="checkbox"
                    @change="updateCheckboxes"
                />
                <label
                    class="border border-light-grey relative flex items-center leading-tight cursor-pointer p-1 rounded-md"
                    :for="`${qName}-${val}`"
                >
                    <div
                        class="tickbox w-8 h-8 mr-2 bg-white rounded flex items-center justify-center relative flex-shrink-0 border border-light-grey"
                    >
                        <div class="portal-tick opacity-0"></div>
                    </div>
                    <div class="absolute portal-focus opacity-0"></div>
                    <span :class="`${index % 2 === 0 ? 'sm:pr-3' : ''}`"> {{ readableTextOptionsArray[index] }}</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        question: {
            type: Object,
            required: true,
        },
        surveyId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chosenInputs: [],
        };
    },
    computed: {
        qName() {
            return this.question.name;
        },
        existingInput() {
            return this.question.userAnswer;
        },
        valuesArray() {
            if (!this.question.choices[0].text) return this.question.choices;
            return Object.values(this.question.choices).map((c) => c.value);
        },
        readableTextOptionsArray() {
            if (!this.question.choices[0].text) return this.question.choices;
            return Object.values(this.question.choices).map((c) => c.text);
        },
    },
    created() {
        if (this.existingInput) this.chosenInputs = this.existingInput;
    },
    watch: {
        // We watch here as the medical history syncing might update this value at any time
        existingInput(val) {
            if (val.length > 0 && val.length !== this.chosenInputs.length) {
                console.log("update from parent");
                this.chosenInputs = val;
            }
        },
    },
    methods: {
        updateCheckboxes() {
            this.$emit("update", {
                val: this.chosenInputs,
                qName: this.qName,
                surveyId: this.surveyId,
            });
        },
    },
};
</script>

<style scoped>
input:checked + label {
    @apply bg-primary border-primary text-over-primary;
}

input:checked + label .tickbox {
    @apply border-primary;
}

.high-c input:checked + label {
    @apply bg-black border-black;
}

.high-c input:checked + label .tickbox {
    @apply border-black;
}

.high-c input:checked + label .portal-tick {
    @apply text-black;
}

input:checked + label .portal-tick {
    @apply text-primary;
    opacity: 1;
}

input:focus + label .portal-focus {
    opacity: 1;
}

@media (min-width: 576px) {
    .col-margins:nth-child(odd) {
        @apply pr-2;
    }

    .col-margins:nth-child(even) {
        @apply pl-2;
    }
}
</style>
