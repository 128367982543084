/* Thanks & complete page for surveysliders. */
<template>
    <div ref="thanks_page">
        <!-- loading -->
        <!-- <div v-if="loading" class="text-center"> -->
        <!-- <img src="@/assets/images/misc/dna_spinner_white.svg" alt="loading" class="inline-block" /> -->
        <!-- </div> -->
        <div>
            <div class="w-12 h-12 mb-8 mx-auto rounded-full bg-success flex items-center justify-center" style="transform: scale(1.5);">
                <div class="portal-tick"></div>
            </div>
            <p class="text-center">
                {{ strings.thanks }}
            </p>

            <!-- ENROLL in the study message [portal-platform only] -->
            <!-- <div v-if="completeBtnType === 'complete_enrol'" class="mt-10">
                <h3 class="text-3xl lg:text-4xl relative" :style="`${in_page ? 'top: 0.5rem' : ''}`">
                    Join the study
                </h3>
                <p class="mt-4">
                    Once you’re enrolled in this study, you won’t be able to change these answers, but you can withdraw at any time.
                </p>
            </div> -->
            <!-- Ready for REFERRAL message [referral only] -->
            <!-- <div v-if="completeBtnType === 'complete_refer'" class="mt-10">
                <h3 class="text-3xl lg:text-4xl relative" :style="`${in_page ? 'top: 0.5rem' : ''}`">
                    Referral
                </h3>
                <p class="mt-4">
                    Once you’re referred to the study team, you won’t be able to change these answers via the sano platform because they would have
                    already been sent.
                </p>
            </div> -->

            <!-- complete button -->
            <div class="relative h-10 items-center">
                <transition name="portal-fade">
                    <button
                        v-if="!completeButtonClicked"
                        data-cy="complete-btn"
                        class="btn bg-primary text-over-primary mt-6 mx-auto block high-c-bg-black high-c-text-white"
                        @click="submitCompletions"
                    >
                        <!-- <span v-if="completeBtnType === 'complete_refer'">Complete and get referred</span>
                        <span v-else-if="completeBtnType === 'complete_enrol'">Complete and enroll</span> -->
                        <span>{{ strings.complete }}</span>
                    </button>
                    <div v-else class="absolute left-0 top-0 w-full">
                        <LoadingSpinner />
                    </div>
                </transition>
            </div>
            <!-- Extra thank you text -->
            <!-- <p v-if="thanksText" class="sano-light leading-tight mt-8">{{ thanksText }}</p> -->
        </div>
    </div>
</template>

<script>
// import { Eligibility } from "@/data/models";
import LoadingSpinner from "@/components/ui/loadingSpinner";
import text from "./thanksPageText";

export default {
    name: "ThanksPage",
    props: {
        isCurrentSlide: Boolean,
        thanksText: {
            type: String,
            required: false,
        },
        user: {
            type: Object,
            required: true,
        },
        // study_type: String,
        // is_survey_study_prescreen: {
        //     type: Boolean,
        //     default: false,
        // },
        // study_id: {
        //     type: String,
        //     required: false,
        // },
    },
    components: {
        LoadingSpinner,
    },
    data() {
        return {
            completeButtonClicked: false,
            loading: false,
            strings: text,
            // completion types are: complete | complete_refer | complete_enrol
            // completeBtnType: "complete",
        };
    },
    methods: {
        submitCompletions() {
            this.completeButtonClicked = true;
            // If this is a completion of the welcome flow, track an event
            // if (this.$route.path.includes("/welcome")) this.$gtm.trackEvent({ event: "completed-welcome-flow" });

            // complete and refer/enrol
            // if (this.completeBtnType !== "complete") {
            //     this.$emit("submit_completions", this.completeBtnType);
            // } else {
            this.$emit("submit-completions");
            // }
        },
    },
    // watch: {
    // In which current_slide is the 'external' slider slide and slide_to_show is what we're showing within this component
    // async current_slide(val) {
    //     if (val) {
    //         this.loading = true;
    //         // If its a sano-platform and the survey IS a prescreen
    //         // > recalculate the eligibility to determine if they are "confirmed" based on the prescreen choices
    //         // If the user is "confirmed", show the "Complete and get referred" OR "Complete and enrol"
    //         if ((this.study_type === "sano-platform" || this.study_type === "referral") && this.is_survey_study_prescreen) {
    //             // give a chance for the last response to be sent (wait 1s)
    //             await new Promise((resolve) => setTimeout(resolve, 1000));
    //             // calculate eligibility to see if they are confirmed
    //             await this.$api.user.read_my_eligibility_for_study(this.study_id, true);
    //             const user_study_eligibility = Eligibility.query()
    //                 .where("study_id", this.study_id)
    //                 .where("user_id", this.user.id)
    //                 .first();
    //             // console.log("Eligibility calculated > ", user_study_eligibility);
    //             if (!user_study_eligibility || !user_study_eligibility.data || !user_study_eligibility.data.eligibility) {
    //                 return;
    //             }
    //             // is a sanoplatform and is fully eligible > gets enrolled
    //             if (this.study_type === "sano-platform" && user_study_eligibility.data.eligibility === "confirmed") {
    //                 this.completeBtnType = "complete_enrol";
    //             }
    //             // is a referral and is fully eligible > gets referred
    //             else if (this.study_type === "referral" && user_study_eligibility.data.eligibility === "confirmed") {
    //                 this.completeBtnType = "complete_refer";
    //             }
    //             // we need to set the 'else' in case a user goes back to change their mind about a question & then returns
    //             // if we don't set this completeBtnType might still have an incorrect value of e.g. "complete_enrol" when the user is not eligible
    //             else {
    //                 this.completeBtnType = "complete";
    //             }
    //         }
    //         // Re-check & emit page height once we have potentially loaded lots more content
    //         this.loading = false;
    //         this.$nextTick(() => {
    //             const lph = this.$refs["thanks_page"]?.clientHeight;
    //             if (lph) this.$emit("loaded_page_height", lph);
    //             // stop loading (wait 150ms, check we're still in the right place)
    //             this.$nextTick(() => {
    //                 if (this.hide_loaded_thanks) this.hide_loaded_thanks = false;
    //             });
    //         });
    //     }
    // },
    // },
};
</script>
