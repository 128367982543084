const visibleIf = {
    parseVisibleif({ visibilityString, surveyId }) {
        if (visibilityString?.[0]?.question) {
            return visibleIf.parseNewerVisibleIf({ visibilityArray: visibilityString, surveyId });
        }
        // This function exists because before the surveyslider was built, survey js was being used to make surveys
        // It was necessary for everything to be backwards compatible, so we take 'visibleIf' strings in the same way
        // However it is easier to deal with an object, so we currently do this conversion before handling the logic
        // Surveyjs had 6 more options but more than these 6 has not yet been needed
        // [spaces needed before " empty" and " contains" to distinguish them from their opposites]
        // console.log("visibilityString", visibilityString);
        const surveyjsTypes = ["notcontains", " contains", "<>", "=", " empty", "notempty"],
            comparisonTypes = ["mustNotContain", "mustContain", "mustNotEqual", "mustEqual", "mustBeEmpty", "mustNotBeEmpty"],
            dependencyList = visibilityString.split("[and]"),
            dependencyArray = [];

        // Break the string into separate dependencies, e.g. Question1 <> "No"
        for (let d = 0; d < dependencyList.length; d += 1) {
            let surveyjsType = null,
                comparisonType = null;
            const dependencyString = dependencyList[d].trim(),
                visibilityObject = {
                    surveyId,
                    conditionsMet: false,
                };

            for (let i = 0; i < surveyjsTypes.length; i += 1) {
                // For each dependency, determine the comparison type
                if (dependencyString.indexOf(surveyjsTypes[i]) > -1) {
                    surveyjsType = surveyjsTypes[i];
                    comparisonType = comparisonTypes[i];
                } else {
                    visibilityObject[comparisonTypes[i]] = null;
                }
            }

            if (surveyjsType) {
                // Save the pieces we need within an object without the surrounding brackets etc.
                const splitDeclaration = dependencyString.split(surveyjsType);
                visibilityObject.dependantQ = this.trimAndRemoveBrackets(splitDeclaration[0]);
                visibilityObject[comparisonType] = this.trimAndRemoveBrackets(splitDeclaration[1]);
                dependencyArray.push(visibilityObject);
            }
        }
        return dependencyArray.length > 0 ? dependencyArray : null;
    },
    trimAndRemoveBrackets(string) {
        // 'empty' and 'not empty' comparison types will not have a comparison string
        if (!string) return true;
        const trimmed = string.trim(),
            removedBracketsOrQuotes = trimmed.substring(1, trimmed.length - 1);
        return removedBracketsOrQuotes;
    },
    parseNewerVisibleIf({ visibilityArray, surveyId }) {
        // With the newer surveybuilder, we still transform the visibility object slightly, but much less
        return visibilityArray.map((obj) => {
            const parsedVisObject = {
                surveyId,
                conditionsMet: false,
                mustNotContain: null,
                mustContain: null,
                mustNotEqual: null,
                mustEqual: null,
                mustBeEmpty: null,
                mustNotBeEmpty: null,
            };

            parsedVisObject.dependantQ = obj.questionName;
            parsedVisObject[obj.comparison] = obj.answer ? obj.answer : true;
            return parsedVisObject;
        });
    },
    testVisibleIf(answer, condition) {
        let response = answer,
            conditionMetNewState = null;

        // Where we have an object response, but it has an 'overall' property to help us keep the visibleIf fairly simple
        if (response && response.overall) response = response.overall;

        if (response || response === null || condition.mustBeEmpty || condition.mustNotContain || condition.mustNotEqual) {
            if (condition.mustEqual) {
                conditionMetNewState = response === condition.mustEqual;
            } else if (condition.mustNotEqual) {
                // Allow undefined response
                conditionMetNewState = !response || response !== condition.mustNotEqual;
            } else if (condition.mustContain) {
                // Allow regex for string responses. condition.mustContain will be implictiely converted to regex & should not include "/" at start & end
                if (response && response.length > 0 && !Array.isArray(response)) {
                    conditionMetNewState = response.match(condition.mustContain) !== null;
                } else if (response) {
                    conditionMetNewState = response.includes(condition.mustContain);
                }
            } else if (condition.mustNotContain) {
                // Allow regex for string responses. Also allow undefined response
                if (response && response.length > 0 && !Array.isArray(response)) {
                    conditionMetNewState = response.match(condition.mustNotContain) === null;
                } else {
                    conditionMetNewState = !response || !response.includes(condition.mustNotContain);
                }
            } else if (condition.mustBeEmpty) {
                // Allow undefined response
                conditionMetNewState = !response || response.length === 0;
            } else if (condition.mustNotBeEmpty) {
                conditionMetNewState = response.length > 0;
            }
        }

        return conditionMetNewState;
    },
};

export default visibleIf;
