<!-- https://www.npmjs.com/package/@vueform/multiselect -->
<template>
    <div class="w-full" :class="`focus-option-${focusedOption}`" data-cy="select-holder">
        <div role="region" class="sr-only" data-cy="select-screenreader-additional-text" aria-live="assertive">
            <div v-if="isOpen">
                <div v-if="inputted && Array.isArray(inputted) && inputted.length > 0">
                    {{ strings.selected }} {{ inputted }}
                    <span v-for="(input, i) in inputted" :key="`input-${i}`">{{ input && input.text ? input.text : input }}</span>
                </div>
                <div v-else-if="inputted && !Array.isArray(inputted)">{{ strings.selected }} {{ inputted.text ? inputted.text : inputted }}</div>
                <div v-else>{{ strings.exp }}</div>

                <div v-if="isSearching">{{ strings.searching }}</div>
                <div v-else-if="noresults">{{ strings["no-result"] }}</div>
                <div v-else-if="selectOpts.length > 0" data-cy="top-result">
                    {{ strings.top.replace("{option}", selectOpts[focusedOption].text ? selectOpts[focusedOption].text : selectOpts[focusedOption]) }}
                </div>
            </div>
        </div>
        <Multiselect
            :ref="`${qName}-multiselect`"
            v-model="inputted"
            :options="selectOpts"
            :mode="multiple ? 'tags' : 'single'"
            :limit="6"
            :disabled="disabled"
            track-by="value"
            label="text"
            searchable
            :filterResults="false"
            :placeholder="placeholder"
            @open="isOpen = true"
            @close="isOpen = false"
            @search-change="onSearch"
            @change="inputChangeFromSelect"
            @keyup.right="nextOption"
            @keyup.left="prevOption"
            @keyup.down="nextOption"
            @keyup.up="prevOption"
            @keydown.enter="selectCurrent(focusedOption)"
        >
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <span class="multiselect-tag" :data-cy="`${option.value}-tag`">
                    {{ option.text }}
                    <span v-if="!disabled" data-cy="clear-single" class="multiselect-tag-remove" @mousedown.stop="handleTagRemove(option, $event)">
                        <span class="multiselect-tag-remove-icon"></span>
                    </span>
                </span>
            </template>
            <template v-slot:clear="{ clear }">
                <button
                    class="multiselect-clear"
                    :aria-label="strings.clear"
                    data-cy="clear-all"
                    @mousedown.stop="clear($event)"
                    @keydown.enter.stop="clear($event)"
                >
                    <span class="multiselect-clear-icon"></span>
                </button>
            </template>
            <template v-slot:option="{ option }" v-if="imageSelect">
                <span>{{ option && option.text ? option.text : option }}</span>
                <div v-if="option" class="w-6 h-4 ml-2 bg-mid-grey relative">
                    <!-- Only load each image when it is being shown -->
                    <LazyLoadingBGimage :mainSrc="option.imgUrl" aria-hidden="true" />
                </div>
                <span class="sr-only">({{ option.altText ? option.altText : "" }})</span>
            </template>
            <template v-slot:singlelabel="{ value }" v-if="imageSelect">
                <div class="flex items-center mr-auto ml-3 multiselect-single-with-img">
                    <span class="block relative text-base">
                        {{ value && value.text ? value.text : value }}
                    </span>
                    <div class="w-6 h-4 ml-2 bg-mid-grey relative">
                        <LazyLoadingBGimage :mainSrc="value.imgUrl" aria-hidden="true" />
                    </div>
                    <span class="sr-only">({{ value.altText ? value.altText : "" }})</span>
                </div>
            </template>
            <template v-slot:caret>
                <div class="w-8 h-8 bg-white relative flex items-center justify-center">
                    <svg viewBox="0 0 10 6" class="w-3 h-3 fill-current" aria-hidden="true">
                        <path
                            d="M9.05692 1.04132C8.75186 0.736225 8.25723 0.736225 7.95218 1.04132L4.96878 4.02463L1.9855 1.04145C1.68045 0.736347 1.18582 0.736347 0.880646 1.04145C0.575592 1.34655 0.575592 1.84121 0.880646 2.14631L4.41653 5.68171C4.72147 5.9865 5.21536 5.9868 5.52054 5.68261L5.52139 5.68171L9.05692 2.14618C9.36209 1.84108 9.36209 1.34642 9.05692 1.04132Z"
                        />
                    </svg>
                </div>
            </template>
        </Multiselect>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import LazyLoadingBGimage from "@/components/ui/lazyLoadingBGimage";
import debounce from "lodash.debounce";
import text from "./surveySelectText";
const algoliasearch = require("algoliasearch");

export default {
    name: "SurveyVueSelect",
    components: {
        Multiselect,
        LazyLoadingBGimage,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        surveyId: {
            type: String,
            required: true,
        },
        disabled: Boolean,
        // Do we want to show an image as part of each option?
        imageSelect: Boolean,
    },
    data() {
        return {
            inputted: null,
            selectOpts: [],
            isSearching: false,
            noresults: false,
            focusedOption: 0,
            rareDiseasesClient: null,
            rareDiseasesIndex: null,
            isOpen: false,
            strings: text,
        };
    },
    computed: {
        qName() {
            return this.question.name;
        },
        multiple() {
            // false by default - allow multiple selections
            return !!this.question.selectMulti;
        },
        usesApi() {
            return this.question.api;
        },
        existingInput() {
            return this.question.userAnswer;
        },
        existingInputArray() {
            // Depending whether or not this is a multi-select, this could be an array or a string - it's helpful to standardise it!
            if (!this.existingInput) return [];
            return Array.isArray(this.existingInput) ? this.existingInput : [this.existingInput];
        },
        placeholder() {
            return this.question.placeholder ? this.question.placeholder : "Start typing...";
        },
    },
    created() {
        console.log(this.question.name, this.usesApi);
        // For a static list, this is simple
        if (this.question.choices && !this.usesApi) {
            this.selectOpts = this.question.choices;
        }
        // For us to re-select existing api inputs, first they need to be in the options list
        // (We save only strings, not objects in all api instances)
        else if (this.existingInput) {
            for (let i = 0; i < this.existingInputArray.length; i++) {
                this.selectOpts.push(this.existingInputArray[i]);
            }
        }

        if (this.usesApi === "raredisease") this.initRareDiseaseSearcher();
    },
    mounted() {
        this.$nextTick(() => {
            const thisSelector = this.$refs[`${this.qName}-multiselect`];
            if (this.existingInput && thisSelector) {
                if (this.multiple) this.inputted = [];
                // Set any existing answers
                for (let i = 0; i < this.existingInputArray.length; i++) {
                    const thisInput = this.existingInputArray[i];
                    if (this.multiple) this.inputted.push(thisInput);
                    else this.inputted = thisInput;
                }
            }
        });
    },
    methods: {
        selectCurrent(focusedOption) {
            const thisSelector = this.$refs[`${this.qName}-multiselect`];
            const opt = this.selectOpts[focusedOption];
            console.log("select", focusedOption, opt);

            if (thisSelector) {
                thisSelector.select(opt);
                thisSelector.clearSearch();
                thisSelector.close();
                this.emitAfterdebounce(this);
            }
        },
        clearSelections() {
            const thisSelector = this.$refs[`${this.qName}-multiselect`];
            if (thisSelector) thisSelector.clear();
            this.emitAfterdebounce(this);
        },
        nextOption() {
            if (this.focusedOption < this.selectOpts.length - 1) this.focusedOption += 1;
        },
        prevOption() {
            if (this.focusedOption > 0) this.focusedOption -= 1;
        },
        inputChangeFromSelect() {
            this.emitAfterdebounce(this);
            this.$nextTick(() => {
                this.focusedOption = 0;
            });
        },
        emitAfterdebounce: debounce((vm) => {
            // We need a small debounce here to make sure we have the latest, correct value.
            vm.$emit("update", {
                val: vm.inputted ? vm.inputted : "",
                qName: vm.qName,
                surveyId: vm.surveyId,
            });
        }, 50),
        onSearch(searchText) {
            // console.log("search", searchText);
            if (this.usesApi) {
                // Api search
                if (searchText && searchText.length > 0) this.apiSearch(this, searchText);
            } else {
                // Non-api - filter options (or reset to original list)
                if (searchText && searchText.length > 0) {
                    this.selectOpts = this.orderOptions(searchText);
                } else {
                    this.selectOpts = this.question.choices;
                }
            }
        },
        orderOptions(searchText) {
            // This is done manually rather than setting filterResults to true because otherwise
            // We do not have access to the filtered list, so our keyboard solution for choosing options can't work
            return this.selectOpts.sort((a, b) => {
                // We may have either an array of strings, or an array of object to search
                // This is rudimentary, but we use an api for all really long lists so it will be very lightly used
                // Where objects are used, track-by is always set to 'value'
                const aIndex = a.value ? a.value.indexOf(searchText) : a.indexOf(searchText);
                const bIndex = b.value ? b.value.indexOf(searchText) : b.indexOf(searchText);
                if (aIndex > -1 && bIndex === -1) {
                    // Search query appears only in A - sort a (element 1) first
                    return -1;
                } else if (bIndex > -1 && aIndex === -1) {
                    // Search query appears only in B - sort b (element 2) first
                    return 1;
                } else if (aIndex === -1 && bIndex === -1) {
                    // Search query appears in neither option - keep original order
                    return 0;
                } else {
                    // The search query appears in both options - sort the first appearance first,
                    // Or keep the original order if identical
                    if (aIndex === bIndex) return 0;
                    else return aIndex > bIndex ? 1 : -1;
                }
            });
        },
        apiSearch: debounce((vm, searchText) => {
            vm.isSearching = true;
            vm.noresults = false;

            if (vm.usesApi === "maps") vm.axiosSearch(searchText);
            else if (vm.usesApi === "raredisease") {
                // Catch potential case where component mounts before algoliasearch is available
                if (vm.rareDiseasesIndex) vm.rareDiseaseSearch(searchText);
                else vm.initRareDiseaseSearcher();
            }
        }, 500),
        initRareDiseaseSearcher() {
            if (algoliasearch) {
                this.rareDiseasesClient = algoliasearch("VT77UO50W9", "c9110fb9a6b6a5777b132aa37f574078");
                this.rareDiseasesIndex = this.rareDiseasesClient.initIndex("rare-diseases");
            }
        },
        rareDiseaseSearch(search) {
            this.rareDiseasesIndex
                .search(search)
                .then(({ hits }) => {
                    const simplified_opts = hits.map((opt) => {
                        // We'll use the condition key to set a diagnosis, so we'll need to slugify the condition name
                        return { value: opt.key, text: opt.name };
                    });
                    this.updateOptions(simplified_opts);
                })
                .catch(() => {
                    this.isSearching = false;
                });
        },
        axiosSearch(search) {
            new window.google.maps.places.AutocompleteService().getPlacePredictions({ input: search }, (placesAr) => {
                const options = placesAr;
                const simplified_opts = options.map((opt) => opt.description);
                this.updateOptions(simplified_opts);
            });
        },
        updateOptions(predictions) {
            this.isSearching = false;
            console.log("options update", predictions);

            if (predictions.length > 0) {
                const uniqPredictions = this.removeDuplicates(predictions);
                // Previously selected options still need to be included
                if (this.inputted) {
                    const currentlySelected = Array.isArray(this.inputted) ? this.inputted : [this.inputted];
                    this.selectOpts = [...uniqPredictions, ...currentlySelected];
                } else {
                    this.selectOpts = uniqPredictions;
                }
            } else {
                this.noresults = true;
                this.selectOpts = [];
            }
        },
        removeDuplicates(predictions) {
            const uniques = [];
            for (let i = 0; i < predictions.length; i += 1) {
                if (!uniques.includes(predictions[i])) uniques.push(predictions[i]);
            }
            return uniques;
        },
    },
};
</script>

<style>
/* holder & input element |*/
.high-c .multiselect,
.high-c .multiselect-dropdown {
    @apply border-black;
}
.multiselect {
    @apply relative mx-auto w-full flex items-center justify-end cursor-pointer border border-mid-grey rounded-md bg-white text-lg leading-tight outline-none p-px;
}
.multiselect input {
    @apply rounded-md py-2;
}
.multiselect.is-open {
    @apply rounded-b-none;
}
.multiselect.is-active {
    outline: 2px solid #0450c3;
}
.hide-focus-children .multiselect.is-active {
    outline: none;
}
.multiselect-multiple-label,
.multiselect-single-label {
    @apply w-full truncate text-black flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-tight pl-3.5 pr-8;
}
.multiselect-search {
    @apply w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5;
}
/* tags, clear, remove, caret */
.multiselect-tags {
    @apply flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-1;
}
.multiselect-tag {
    @apply text-base py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap bg-lightest-grey;
}
.multiselect-tag.is-disabled {
    @apply pr-2 opacity-50;
}
.multiselect-tag-remove {
    @apply flex items-center justify-center py-1 px-2 mx-0.5 rounded;
    transform: scale(0.8);
}
.multiselect-clear {
    @apply absolute z-10 transition duration-300 flex-shrink-0 flex-grow-0 flex py-1 px-2 mr-2 bg-white;
}
.multiselect-tag-remove-icon,
.multiselect-clear-icon {
    -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    @apply bg-black bg-center bg-contain bg-no-repeat inline-block opacity-75;
    width: 10px;
    height: 16px;
}
.multiselect-tags-search-wrapper {
    @apply inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full;
}
.multiselect-tags-search {
    @apply absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full bg-transparent;
}
.multiselect-tags-search-copy {
    @apply invisible whitespace-pre-wrap inline-block h-px;
}
.multiselect-placeholder {
    @apply flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-tight pl-3.5;
}
.multiselect-caret.is-open {
    @apply rotate-180;
}
.multiselect-spinner {
    /* bg-multiselect-spinner */
    @apply bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0;
}
/* Dropdown & options */
.multiselect-dropdown {
    @apply max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-light-grey -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b;
}
.multiselect-dropdown.is-top {
    @apply -translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t;
}
.multiselect-dropdown.is-hidden {
    @apply hidden;
}
.multiselect-options {
    @apply flex flex-col p-0 m-0 list-none;
}
.multiselect-option {
    @apply w-full flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3;
}
.focus-option-0 .multiselect-option:first-child,
.focus-option-1 .multiselect-option:nth-child(2),
.focus-option-2 .multiselect-option:nth-child(3),
.focus-option-3 .multiselect-option:nth-child(4),
.focus-option-4 .multiselect-option:nth-child(5),
.multiselect-option.is-pointed {
    @apply bg-lightest-grey text-black;
}
.multiselect-no-options,
.multiselect-no-results {
    @apply py-2 px-3 bg-white;
}
.multiselect-fake-input {
    @apply bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent;
}
.multiselect-spacer {
    @apply h-9 py-px box-content;
}
</style>
