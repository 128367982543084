<template>
    <div class="bg-background h-full min-h-screen flex items-center justify-center overflow-hidden">
        <transition-group name="portal-fade" appear>
            <!-- Checking survey information -->
            <div v-if="loading" key="load" class="w-full absolute left-0" style="top: 50%; transform: translateY(-50%)">
                <p class="sr-only">{{ strings.loading }}</p>
                <LoadingSpinner />
            </div>
            <!-- Found either valid survey or account questions -->
            <SurveySlider
                v-else-if="includeAccountQs || fetchedSurvey"
                key="survey"
                :includeAccountQs="includeAccountQs"
                :fetchedSurvey="fetchedSurvey"
                :fetchedSurveyResponse="fetchedSurveyResponse"
                :specificAccountSlides="specificAccountSlides"
                :completionUrl="previousRoute ? previousRoute : '/home'"
            />
            <!-- No valid survey -->
            <div v-else key="no-valid-survey">
                {{ strings["no-valid-survey"] }}
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import LoadingSpinner from "@/components/ui/loadingSpinner";
import SurveySlider from "@/components/survey/surveySlider";
import { User, Survey, Response } from "@/data/models";
import text from "./surveyText";

export default {
    name: "Survey",
    components: {
        SurveySlider,
        LoadingSpinner,
    },
    data() {
        return {
            surveyId: null,
            includeAccountQs: false,
            specificAccountSlides: false,
            loading: true,
            previousRoute: null,
            strings: text,
        };
    },
    beforeRouteEnter(to, from, next) {
        // Most often, after completing a survey, we'll want to send the user back to the page they were previously on
        next((vm) => {
            vm.previousRoute = from.path;
        });
    },
    async mounted() {
        // Unlike with blogposts, we're checking the route info & getting the survey id on mounted
        // rather than watching for a change. This is because users will not be going directly from one survey to another.
        this.surveyId = this.$route.params.id;

        // Fetch all the information that we need
        if (this.surveyId === "useraccount") {
            // A special case - in this case there is no 'survey' in the db as account questions are hardcoded
            // (& may not be changed by portal admins)
            await this.$api.user.read_user();
        } else {
            await Promise.all([
                Survey.$read({ by: [this.surveyId] }),
                this.$api.user.read_my_responses_for_surveys([this.surveyId]),
                this.$api.user.read_user(),
            ]);
        }

        // Account questions may be added if either we're asking only those, or if we're adding them to a welcome survey
        if (this.surveyId === "useraccount" || this.surveyId === this.welcomeSurveyId) this.addAccountQuestions();

        this.loading = false;
    },
    computed: {
        ...mapFields(["welcomeSurveyId"]),
        user() {
            return User.query().first();
        },
        // We exclude archived surveys here
        // TODO: for now we need to show unpublished surveys so they may be previewed -
        // how can we best restrict this to admin members with the correct permissions
        // TODO: Once studies are part of the portals, we'll also need to check (for study-surveys) whether:
        // a) the related study is archived / unpublished b) whether this user is eligible / enrolled / should see this survey
        fetchedSurvey() {
            return Survey.query().where("id", this.surveyId).where("no_longer_active", false).first();
        },
        fetchedSurveyResponse() {
            if (!this.fetchedSurvey) return null;
            return Response.query().where("survey_id", this.fetchedSurvey.id).where("user", this.user.id).first();
        },
    },
    methods: {
        addAccountQuestions() {
            // Instead we'll pass a param to the surveyslider to indicate which account questions we want to ask
            this.includeAccountQs = true;
            // Previously we have found it useful to allow users to update only certain account questions,
            // rather than the whole survey. (Use slides=examplSlideeA+exampleSlideC to include more than one slide)
            // We'll want to transform this information from string to array
            if (this.$route.query.slides) this.specificAccountSlides = this.$route.query.slides.split("_");
            console.log(this.$route.query.slides, this.specificAccountSlides);
        },
    },
};
</script>
