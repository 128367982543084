export default {
    "num-errors": {
        // All caps format here is for easy integration with a library
        NOT_A_NUMBER: "Hmm, this doesn't look like a phone number.",
        // INVALID_COUNTRY: This should theoretically not be possible as users are picking from a pre-defined list
        INVALID_COUNTRY: "Hmm, this doesn't look like a phone number.",
        TOO_SHORT: "Hmm, it looks like you've entered too few numbers. Please check and try again.",
        INVALID_LENGTH: "Hmm, this doesn't look like a valid phone number. Please check and try again.",
        TOO_LONG: "Hmm, it looks like you've entered too many numbers. Please check and try again.",
    },
    "country-err": "Please select a country.",
    "missing-err": "Please enter your phone number.",
    "invalid-err": "This number isn't valid for the country you selected, please check it and try again.",
};
