export default {
    examples: {
        med_metabolic_endocrine: "E.g. Type 2 diabetes, Galactosemia",
        med_liver_pancreas_spleen_kidney: "E.g. NAFLD or NASH, Hepatitis, Cirrhosis",
        med_pregnancy_complications: "E.g. Eclampsia, Gestational Diabetes",
        med_blood: "E.g. Anaemias, Coagulation defects",
        med_rare: "E.g. Phelan McDermid Syndrome, Muscular Dystrophy",
        med_mh: "E.g. Anxiety & panic attacks, Bipolar disorder",
        med_neurological: "E.g. Migraines, Aneurysm, ALS, Parkinson's",
        med_cancer: "E.g. Leukemia, Lymphoma, Melanoma",
        med_cardiovascular: "E.g. Stroke, Congenital heart disease",
        med_respiratory: "E.g. Asthma, Cystic fibrosis",
        med_immune: "E.g. Lupus, MS, Type 1 Diabetes",
        med_digestive: "E.g. Celiac disease, Crohn's disease, Gallstones",
        med_bone: "E.g. Scoliosis, Osteoarthritis, Gout",
        med_skin: "E.g. Eczema, Vitiligo, Psoriasis, Acne",
        med_sense: "E.g. Deafness, Glaucoma, Microphthalmia, Ptosis",
        med_srh: "E.g. Endometriosis, Primary infertility",
    }
};