<template>
    <div class="radios-holder py-2">
        <div
            v-for="(val, index) in valuesArray"
            :key="`${qName}-${val}`"
            class="portal-radio my-2 bg-lightest-grey rounded-md border border-light-grey text-base"
        >
            <input
                :id="`${qName}-${val}`"
                :data-cy="`${qName}-${val}`"
                :value="val"
                v-model="chosenInput"
                :name="`${qName}-radio`"
                class="sr-only"
                type="radio"
                :disabled="disable"
                @input="updateRadios"
            />
            <label class="relative flex items-center leading-tight cursor-pointer p-1 rounded-md" :for="`${qName}-${val}`">
                <div class="tickbox w-8 h-8 mr-2 bg-white border border-light-grey rounded flex items-center justify-center relative flex-shrink-0">
                    <div class="portal-tick opacity-0"></div>
                </div>
                <div class="absolute focused opacity-0 portal-focus"></div>
                <span :class="`${index % 2 === 0 ? 'sm:pr-3' : ''}`"> {{ readableTextOptionsArray[index] }}</span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "Radiobuttons",
    props: {
        question: {
            type: Object,
            required: true,
        },
        surveyId: {
            type: String,
            required: true,
        },
        disable: Boolean,
    },
    data() {
        return {
            chosenInput: null,
        };
    },
    created() {
        if (this.existingInput) this.chosenInput = this.existingInput;
    },
    computed: {
        // We'll allow an array of options, or an object with both '[readable] text' and 'value' strings
        valuesArray() {
            if (!this.question.choices[0].text) return this.question.choices;
            return Object.values(this.question.choices).map((c) => c.value);
        },
        readableTextOptionsArray() {
            if (!this.question.choices[0].text) return this.question.choices;
            return Object.values(this.question.choices).map((c) => c.text);
        },
        existingInput() {
            return this.question.userAnswer;
        },
        qName() {
            return this.question.name;
        },
    },
    methods: {
        updateRadios(event) {
            console.log("emit", event);
            this.$emit("update", {
                val: event.target.value,
                qName: this.qName,
                surveyId: this.surveyId,
            });
        },
    },
};
</script>

<style scoped>
input:checked + label {
    @apply bg-primary text-over-primary border-primary;
}

input:checked + label .portal-tick {
    @apply text-primary;
    opacity: 1;
}

input:checked + label .tickbox {
    @apply border-primary;
}

.high-c input:checked + label .tickbox {
    @apply border-black;
}

.high-c input:checked + label {
    @apply bg-black border-primary;
}

.high-c input:checked + label .portal-tick {
    @apply text-black;
}

.focused {
    @apply rounded-md;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
}

input:focus + label .focused {
    opacity: 1;
}
</style>
