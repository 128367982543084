const vue_touch = {
    el: null,
    instance: null,
    is_bound: false,
    tracking_move: false,
    start_x: null,
    start_y: null,
    y_movement_limit: 20,
    x_movement_reqd: 30,
    // This passed through from the parent and allows us to disable if required.
    ok_to_swipe: null,
    page_transitioning: false,
    touchStartEvent: (e) => {
        if (vue_touch.ok_to_swipe) {
            if (!vue_touch.tracking_move) {
                vue_touch.el.addEventListener("touchmove", vue_touch.touchMoveEvent);
                vue_touch.tracking_move = true;
            }
            vue_touch.start_x = e.touches[0].clientX;
            vue_touch.start_y = e.touches[0].clientY;
        }
    },
    touchMoveEvent: (e) => {
        vue_touch.checkForSwipe(e.touches[0].clientX, e.touches[0].clientY);
    },
    touchEndEvent: () => {
        vue_touch.stopTracking();
    },
    touchCancelEvent: () => {
        vue_touch.stopTracking();
    },
    checkForSwipe(new_x, new_y) {
        if (!vue_touch.instance.jumpingSlides) {
            const diff_y = vue_touch.start_y - new_y;
            const diff_x = vue_touch.start_x - new_x;
            const y_within_limit = diff_y > vue_touch.y_movement_limit * -1 && diff_y < vue_touch.y_movement_limit;
            const x_move_enough = diff_x > vue_touch.x_movement_reqd || diff_x < vue_touch.x_movement_reqd * -1;

            if (y_within_limit && x_move_enough && diff_x > 0) {
                // send show "next" event
                vue_touch.instance.findNextVisiblePage(1);
                vue_touch.stopTracking();
            } else if (y_within_limit && x_move_enough && diff_x < 0) {
                // send show "previous" event
                vue_touch.instance.findNextVisiblePage(-1);
                vue_touch.stopTracking();
            }
        }
    },
    stopTracking() {
        vue_touch.el.removeEventListener("touchmove", vue_touch.touchMoveEvent);
        vue_touch.tracking_move = false;
    },
};

const swipe = {
    created: ($el, binding) => {
        if (vue_touch && !vue_touch.is_bound) {
            // Add listeners to selected elements
            vue_touch.el = $el;
            vue_touch.instance = binding.instance;
            vue_touch.ok_to_swipe = binding.value;

            $el.addEventListener("touchstart", vue_touch.touchStartEvent);
            $el.addEventListener("touchend", vue_touch.touchEndEvent);
            $el.addEventListener("touchcancel", vue_touch.touchCancelEvent);

            vue_touch.is_bound = true;
        }
    },
    unmounted: () => {
        vue_touch.is_bound = false;
    },
    updated: ($el, binding) => {
        // Has 'okToSwipe' been updated?
        if (binding.value !== binding.oldValue) {
            vue_touch.ok_to_swipe = binding.value;
        }
    },
};

export { swipe };
