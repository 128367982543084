/* This js file takes the "intro" functionality out of the surveyslider file, so as to keep that as neat as possible.
The "intro" questions set user properties rather than updating a survey response like all other surveys.
This file also handles any translation between what is expected to be sent to the db and a more UX friendly series of questions */
import { User, Diagnosis, Condition } from "@/data/models";
import debounce from "lodash.debounce";
import { apiPlugin } from "@/plugins/api";
import accQs from "@/../../shared/js/accountQuestions";

const accountSettingsFunctions = {
    ethnicitySecondQKeyUK: {
        // Which narrow level question name [left], corresponds to which broad level answer
        ethnicity_narrow_uk_asian_asian_british: "Asian or Asian British",
        // (Question length character limit!)
        ethnicity_narrow_uk_black_african_black_bri_cari: "Black, African, Black British or Caribbean",
        ethnicity_narrow_uk_mixed_multiple_ethnic_groups: "Mixed or multiple ethnic groups",
        ethnicity_narrow_uk_white: "White",
        ethnicity_narrow_uk_another: "Another ethnic group",
    },

    getExistingAnswers() {
        // Function called in SurveySlider where account info questions are included in the slider
        const user = User.query().first();
        const { name, fullname, sex, represented_by, phone, shipping_address, birthday, location, ethnicity } = user;

        // Only set 'represented by' if we have data, as this is a single column in the db but involves 3 questions for better UX
        let representing_self_or_other_answer = null,
            represented_by_answer = null;

        if (represented_by) {
            representing_self_or_other_answer = represented_by === "Myself" ? "Myself" : "Someone else";
            represented_by_answer = represented_by === "Myself" ? null : represented_by;
        }

        // Also with ethnicity - this single json field maps to various questions depending on user location
        const ethnicitySeparateQAs = {
            ethnicity_broad_uk: ethnicity?.uk?.broad,
            ethnicity_us: ethnicity?.us?.hispanic,
            race_us: ethnicity?.us?.race,
        };

        const ukSecondLevelQs = Object.keys(accountSettingsFunctions.ethnicitySecondQKeyUK),
            ukFirstLevelAs = Object.values(accountSettingsFunctions.ethnicitySecondQKeyUK);
        for (let i = 0; i < ukSecondLevelQs.length; i++) {
            // The narrow question answers should be null except for the one which corresponds to the broad question answer
            ethnicitySeparateQAs[ukSecondLevelQs[i]] = ethnicity?.uk?.broad === ukFirstLevelAs[i] ? ethnicity?.uk?.narrow : null;
            ethnicitySeparateQAs.ethnicity_another_custom_text_uk =
                ethnicity?.uk?.broad === "Another ethnic group" ? ethnicity?.uk?.narrow_custom_answer : null;
        }

        // And we need to map from a list of diagnoses to a whole set of medical history questions
        const medicalHistorySeparateQAs = accountSettingsFunctions.getmedHistResponses();

        return {
            phone,
            name,
            fullname,
            sex,
            birthday: birthday,
            birthday_us_format: birthday,
            location,
            shipping_address,
            representing_self_or_other: representing_self_or_other_answer,
            represented_by: represented_by_answer,
            // If the consent box is not ticked, represented_by can only be "Myself" or null, so an answer here means it must be currently ticked
            representation_consent: represented_by_answer ? ["Yes"] : null,
            ...ethnicitySeparateQAs,
            ...medicalHistorySeparateQAs,
        };
    },

    getDiagnosedConditionKeys(diagnosedCondIds) {
        // Return a list of condition keys, rather than ids, so we can compare with our category lists
        return Condition.query()
            .where((c) => {
                return diagnosedCondIds.includes(c.id);
            })
            .all()
            .map((c) => c.key);
    },

    getmedHistResponses() {
        // Get a list of ids of diagnosed conditions for the user
        const user = User.query().first();
        const diagnosedCondIds = Diagnosis.query()
            .where("user", user.id)
            .all()
            .map((d) => d.condition_id);
        const diagnosedCondKeys = accountSettingsFunctions.getDiagnosedConditionKeys(diagnosedCondIds);
        const eachCatName = Object.keys(accQs.conditionsByCategory);
        const answers = {};
        const diagnosisCategories = [];
        const nonRareDiagnoses = [];
        const rareDiagnoses = [];

        for (let c = 0; c < eachCatName.length; c++) {
            const thisCatName = eachCatName[c];
            // For each category, get diagnosed conditions
            const thisCatDiagnosed = accountSettingsFunctions.getDiagnosedCondKeysByCat(thisCatName, diagnosedCondKeys);
            // Add any categories with diagnoses in to the 'ticked category list'
            if (thisCatDiagnosed.length > 0) diagnosisCategories.push(thisCatName);

            // Note the exact diagnosed conditions per category (or an empty array)
            answers[thisCatName] = thisCatDiagnosed;
            // Also note all categorised conditions, so that we can find diagnoses which should be shown in 'rare'
            // (We do this instead of using a comparison list because that list would be ~20,000 conditions long)
            nonRareDiagnoses.push(...thisCatDiagnosed);
        }

        // Check for rare conditions (these will be diagnoses which do not appear in any other category)
        for (let i = 0; i < diagnosedCondKeys.length; i++) {
            console.log(diagnosedCondKeys[i], nonRareDiagnoses.includes(diagnosedCondKeys[i]));
            if (!nonRareDiagnoses.includes(diagnosedCondKeys[i])) rareDiagnoses.push(diagnosedCondKeys[i]);
        }
        // Set the rare category as ticked if we have diagnoses not in another category
        if (rareDiagnoses.length > 0) {
            diagnosisCategories.push("med_rare");
            answers["med_rare"] = rareDiagnoses;
        }

        answers.med_categories = diagnosisCategories;
        return answers;
    },
    getDiagnosedCondKeysByCat(catName, diagnosedCondKeys) {
        // Given a category name, and overall list of diagnosed condition keys,
        // return a list of those belonging to this category
        // (If the category is rare, we don't have a list to compare to, and should return nothing for now)
        // (See the getmedHistResponses function for the adding of rare diagnoses to the user response)
        if (catName === "med_rare") return [];
        const catConditions = accQs.conditionsByCategory[catName].map((c) => c.value);
        return catConditions.filter((condKey) => diagnosedCondKeys.includes(condKey));
    },
    handleUserUpdate: debounce(
        (inputObject, allIntroAnswers) => {
            const simpleFieldsToUpdate = ["name", "location", "sex", "fullname", "phone", "birthday", "birthday_us_format"];
            // Determine which field we are changing as field-specific work may be needed
            if (simpleFieldsToUpdate.includes(inputObject.qName)) {
                // -------------------------------------- Update user.name (already validated)
                // (birthday_us_format has a different question name but we still want to fill out the same field)
                const nameToUse = inputObject.qName === "birthday_us_format" ? "birthday" : inputObject.qName;
                console.log(inputObject.qName, inputObject.val);
                accountSettingsFunctions.updateUserFieldAndFetchAgain(nameToUse, inputObject.val);
            } else if (inputObject.qName === "representing_self_or_other") {
                // -------------------------------------- Update user.represented_by
                // Update only if answer is 'Myself' as follow up questions must be answered before we can set another value.
                if (inputObject.val === "Myself") {
                    accountSettingsFunctions.updateUserFieldAndFetchAgain("represented_by", "Myself");
                }
            } else if (inputObject.qName === "represented_by") {
                accountSettingsFunctions.updateRepresentedByOther(allIntroAnswers.representation_consent, inputObject.val);
            } else if (inputObject.qName === "representation_consent") {
                accountSettingsFunctions.updateRepresentedByOther(inputObject.val, allIntroAnswers.represented_by);
            } else if (/^ethnicity_/.test(inputObject.qName) || inputObject.qName === "race_us") {
                // -------------------------------------- Update user.ethnicity
                accountSettingsFunctions.updateEthnicity(inputObject, allIntroAnswers);
            }
        },
        250,
        { leading: true, trailing: true }
    ),
    updateRepresentedByOther: (consent_val, value) => {
        // Changing either the 'represented_by' or 'representation_consent' can update user.represented_by,
        // but only if the answer to the other question is correct (so 'representation_consent' === 'Yes' and 'represented_by' in not unanswered)
        // Unticking the consent box should set the value back to null
        let set_representation_to = null;
        if (consent_val && consent_val.includes("Yes") && value) set_representation_to = value;

        accountSettingsFunctions.updateUserFieldAndFetchAgain("represented_by", set_representation_to);
    },
    updateEthnicity: (inputObject, allIntroAnswers) => {
        // Where this question is asked in 2 levels, the second should only be filled if it corresponds to the first
        const ukSecondLevelQs = Object.keys(accountSettingsFunctions.ethnicitySecondQKeyUK),
            ukFirstLevelAs = Object.values(accountSettingsFunctions.ethnicitySecondQKeyUK);
        let ukSecondLevelA = null,
            ukSecondLevelCustom = null;

        if (allIntroAnswers.ethnicity_broad_uk) {
            const ukFirstLevelAIndex = ukFirstLevelAs.findIndex((val) => val === allIntroAnswers.ethnicity_broad_uk);
            // Set these answers if they are not undefined
            ukSecondLevelA = allIntroAnswers?.[ukSecondLevelQs[ukFirstLevelAIndex]] ? allIntroAnswers[ukSecondLevelQs[ukFirstLevelAIndex]] : null;
            ukSecondLevelCustom = allIntroAnswers?.ethnicity_another_custom_text_uk ? allIntroAnswers?.ethnicity_another_custom_text_uk : null;
        }

        const ethnicityJsonObj = {
            uk: {
                broad: allIntroAnswers.ethnicity_broad_uk ? allIntroAnswers.ethnicity_broad_uk : null,
                narrow: ukSecondLevelA,
                narrow_custom_answer: ukSecondLevelCustom,
            },
            us: {
                hispanic: allIntroAnswers.ethnicity_us ? allIntroAnswers.ethnicity_us : null,
                race: allIntroAnswers.race_us ? allIntroAnswers.race_us : null,
            },
        };

        console.log("update ethnicity", allIntroAnswers, inputObject, ethnicityJsonObj);
        accountSettingsFunctions.updateUserFieldAndFetchAgain("ethnicity", ethnicityJsonObj);
    },
    updateUserFieldAndFetchAgain: (field, value) => {
        apiPlugin.user.update_user_field(field, value).then(() => {
            apiPlugin.user.read_user();
        });
    },
    settingDiagnoses: false,
    diagnosisTimeout: null,
    handleDiagnosisUpdate: async (allIntroAnswers) => {
        // Setting the diagnoses takes longer than updating a field, and the user may make lots of
        // changes fast using the checkboxes, so we check for whether the last update has finished before
        // re-running the function. When it runs we check all medical categories (not just the one from
        // the latest inputObject) to sync things up fully, so that whenever the last instance run its captures everything
        clearTimeout(accountSettingsFunctions.diagnosisTimeout);

        if (accountSettingsFunctions.settingDiagnoses) {
            // We need to check for ongoing diagnosis setting here on top of the debounce as it may take more than 500ms
            accountSettingsFunctions.diagnosisTimeout = setTimeout(() => {
                console.log("wait");
                accountSettingsFunctions.handleDiagnosisUpdate(allIntroAnswers);
            });
        } else {
            accountSettingsFunctions.settingDiagnoses = true;
            // make a single array of all ticked condition keys
            const eachCatName = Object.keys(accQs.conditionsByCategory);
            let tickedCondKeys = [];
            for (let c = 0; c < eachCatName.length; c++) {
                if (allIntroAnswers[eachCatName[c]].length > 0) {
                    tickedCondKeys = [...tickedCondKeys, ...allIntroAnswers[eachCatName[c]]];
                }
            }
            // Establish existing diagnoses
            await Promise.all([apiPlugin.user.read_my_diagnoses(), apiPlugin.user.read_my_diagnosed_conditions()]);
            const user = User.query().first();
            const diagnosedConds = Diagnosis.query().where("user", user.id).all();
            // And get the associated condition keys
            const diagnosedCondKeys = accountSettingsFunctions.getDiagnosedConditionKeys(diagnosedConds.map((d) => d.condition_id));

            // Compare the 2 arrays to find out what diagnosis we want to add or remove
            const diagnosesToAdd = tickedCondKeys.filter((condition) => !diagnosedCondKeys.includes(condition));
            const diagnosesToRemove = diagnosedCondKeys.filter((condition) => !tickedCondKeys.includes(condition));
            console.log("------------------------------ add", diagnosesToAdd);
            console.log("------------------------------ remove", diagnosesToRemove);

            for (let dta = 0; dta < diagnosesToAdd.length; dta++) {
                try {
                    await apiPlugin.user.create_diagnosis_for_condition_key(diagnosesToAdd[dta]);
                } catch(e) {
                    console.log("Error setting diagnosis", e);
                    accountSettingsFunctions.settingDiagnoses = false;
                }
            }
            for (let dtr = 0; dtr < diagnosesToRemove.length; dtr++) {
                const thisDiagnosisIndex = diagnosedCondKeys.indexOf(diagnosesToRemove[dtr]);
                const diagnosis = diagnosedConds[thisDiagnosisIndex];
                try {
                    await apiPlugin.user.delete_diagnosis_for_condition_key(diagnosis.id, diagnosesToRemove[dtr]);
                } catch(e) {
                    console.log("Error deleting diagnosis", e);
                    accountSettingsFunctions.settingDiagnoses = false;
                }
            }
            accountSettingsFunctions.settingDiagnoses = false;
        }
    },
};

export default accountSettingsFunctions;
